import { useSearchParams } from "react-router-dom";


var axios = require("axios").default;


export default function FavClient({
    type='Button',
    action='submit',
    text="Login here!"
}){    
    const [searchParams, setSearchParams] = useSearchParams();    
    const handleSubmit=(e)=>{        
        e.preventDefault();
        console.log("boton presionado")                   
        const url = process.env.REACT_APP_AUTH_SERVER + '/o/authorize/?response_type=code&code_challenge='+process.env.REACT_APP_CODE_VERIFIER+'&client_id='+process.env.REACT_APP_CLIENT_ID+'&redirect_uri='+process.env.REACT_APP_REDIRECT_URL
        window.location.replace(url);
    }

      // De forma similar a componentDidMount y componentDidUpdate
    const getToken = () => {        
        // Actualiza el título del documento usando la API del navegador
        const vcode = searchParams.get("code")
        console.log(vcode)
        if(vcode != null) {                                    
            var options = {
              method: 'POST',
              url: process.env.REACT_APP_AUTH_SERVER + '/o/token/',
              headers: {    
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              data: {
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET,
                code: vcode,
                redirect_uri: process.env.REACT_APP_REDIRECT_URL,
                code_verifier: process.env.REACT_APP_CODE_VERIFIER,
                grant_type: process.env.REACT_APP_GRANT_TYPE
              }
            };
            axios.defaults.withCredentials = true;
            axios.request(options).then(function (response) {
              console.log(response.data);
              console.log(response.data.id_token);

            }).catch(function (error) {
              console.error(error);
            });   
    };
}

    return(
        <>
        {
            type==='Button' ?
            <div>
                <button
                    type={action}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
                    onClick={handleSubmit}>
                    {text}
                </button>
                <button
                    type={action}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
                    onClick={getToken}>
                    Get token!
                </button>            
            </div>
            
            :
            <></>
        }
        </>
    )
}