import { useState, useEffect } from 'react';
import { loginWithoutPassword } from "../constants/formFields";
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/Error';
//axios.defaults.withCredentials = true;


const fields=loginWithoutPassword;
let fieldsState = {};
fields.forEach(field=>fieldsState[field.id]='');

export default function Plunder(){
    const [userLogged, setUserLogged]=useState(false);
    const [loading, setLoading]=useState(false);
    const [isDiseable, setIsDisable]=useState(false);
    const [ecwidUserExist, setEcwidUserExist]=useState(true);
    const [loginState, setLoginState]=useState(fieldsState);
    const [isCRFSToken, setIsCRFSToken]=useState(false);
    const [searchParams, setSearchParams] = useSearchParams();    

  

    useEffect(() => { 
        setUserLogged(JSON.parse(window.sessionStorage.getItem("userLogged")));
    }, []);

    
    const handleChange=(e)=>{
        setLoginState({...loginState,[e.target.id]:e.target.value})
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        authenticateUser();
    }

    const redirect_plunder = () => {
        console.log('button clicked');
        window.location.replace('https://plunderdesign.com');
    }


    async function postData(url = '', data = {}) {
    // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',     
            credentials: 'include',     
            headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": process.env.REACT_APP_AUTH_SERVER
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
        return response.json(); // parses JSON response into native JavaScript objects
        }


    const RequestFunction = (endpoint, data = {}, method = "GET") => {
        try {
            return new Promise((resolve, reject) => {                
    
            fetch(`https://authworkinglive.com/${endpoint}`, {
                method: method,
                body: JSON.stringify(data),
                headers: { "Content-Type": "application/json",
                'access-control-allow-origin': 'https://authworkinglive.com'
            },
            })
                .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                reject(response);
                })
                .then((json) => resolve(json))
                .catch((err) => reject(err));
            });
        } catch (err) {
            console.log("ERROR ", err);
        }
        
        };
        

    //Handle Login API Integration here
    //Handle Login API Integration here
    const authenticateUser = () =>{  
        setLoading(true);   
        setIsDisable(true);
        
        const payload = loginState
        payload.org = 'plunder'
        console.log("payload: ", payload)

        postData(process.env.REACT_APP_AUTH_SERVER + '/auth/loginv2/', payload)
        .then((response) => {
            console.log(response)
            if (response['ecwid-user-exist'] == true) {
                window.sessionStorage.setItem("userLogged", true);
                setUserLogged(true);  
                setLoading(false);  
                console.log(response)             
            } else {
                setEcwidUserExist(false)
                console.log(response)             
            }
        })
        .catch(error => {
            console.log("ERROR: ", error)
            if (error.response.data === 'User matching query does not exist.'){
                setEcwidUserExist(false)
                console.log(ecwidUserExist)
            }
            if (error.response.data.detail === 'CSRF Failed: CSRF token missing.'){                

                window.location.replace('https://plunderdesign.com/shoppingcart/store/account');                
            }  
        });
    }

    return(
        <>                
            {
                userLogged ? 

                    (<div className='px-8 '>
                        <h5 className="px-8 text-center mb-4 text-2xl font-bold tracking-tight text-zinc-800 dark:text-white">You are signed in</h5>                    
                            <div className='px-8 flex'>
                                <svg className="h-8 w-8 text-green-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />  <polyline points="22 4 12 14.01 9 11.01" /></svg>
                                <h1 className="px-4 p-1 text-sm">Check your email</h1>
                            </div>                    
                    </div>)
                : 
                ecwidUserExist ? 
                (<div>
                    <h5 className="px-8 font-ibm text-center mb-4 text-2xl font-bold tracking-tight text-zinc-800 dark:text-white">Sign in</h5>
                    <p className="mb-4 font-ibm text-gray-700 dark:text-gray-400">                
                        Enter your email and we’ll send you a special link that will sign you in instantly.
                    </p>            
                    <form onSubmit={handleSubmit}>
                        <div className="-space-y-px">
                            {                   
                                fields.map(field=>
                                    <Input
                                        key={field.id}
                                        handleChange={handleChange}
                                        value={loginState[field.id]}
                                        labelText={field.labelText}
                                        labelFor={field.labelFor}
                                        id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        isRequired={field.isRequired}
                                        placeholder={field.placeholder}
                                        disabled={isDiseable ? 'disabled' : ''}
                                        customClass="bg-gray-50 border text-zinc-900 text-sm rounded-lg focus:ring-zinc-500 focus:border-zinc-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                />                                
                                )
                            }      
                        </div>  
                        {
                            loading ? 
                            <div>                            
                                <button type="button" className="w-full text-white bg-zinc-700 hover:bg-zinc-800 focus:ring-4 focus:outline-none focus:ring-zinc-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" disabled>
                                    <AutorenewIcon className="animate-spin" style={{ color: 'white' }}/> 
                                    Processing...
                                </button>
                            </div>                               
                            :
                            <FormAction handleSubmit={handleSubmit} customClass="w-full text-white bg-zinc-700 hover:bg-zinc-800 focus:ring-4 focus:outline-none focus:ring-zinc-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" text="Login"/>                                                                                       
                          
                        }                            
                    </form>
                    <>
                        <button onClick={redirect_plunder} className="mt-2 w-full text-white bg-zinc-700 hover:bg-zinc-800 focus:ring-4 focus:outline-none focus:ring-zinc-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Return</button>                  
                    </>
                </div>)
                :
                (
                    <div className='px-8'>
                        <h5 className="px-8 font-ibm text-center mb-4 text-2xl font-bold tracking-tight text-zinc-800 dark:text-white">Account not found</h5>
                            <div className='px-8 flex'>                                
                                <button onClick={redirect_plunder} id="plunder" className=" w-full text-white bg-zinc-700 hover:bg-zinc-800 focus:ring-4 focus:outline-none focus:ring-zinc-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Plunder</button>
                            </div>                    
                    </div>
                )
            }
        </>

        
    )
}
