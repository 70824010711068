import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,  
} from "react-router-dom";
import SignupPage from './pages/Signup';
import LoginPage from './pages/Login';
import PlunderPage from './pages/Plunder';
import FavClient from './pages/FavClient'

function App() {
  return (
    <div className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-zinc-50 dark:bg-gray-900">
    <div className="max-w-md w-full space-y-8 ">
     <BrowserRouter>
        <Routes>
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/plunder" element={<PlunderPage text={{heading: "Login to your account", paragraph: "",linkName: "",linkUrl: ""}} />} />
            <Route path="/signup" element={<SignupPage/>} />
            <Route path="/fav-client" element={<FavClient/>} />
        </Routes>
      </BrowserRouter>
    </div>
  </div>
  );
}


export default App;