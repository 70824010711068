import Header from "../components/Header"
import FavClient from "../components/FavClient"

export default function LoginPage(){
    return(
        <>
             <Header
                heading="Favorite Client login"
                paragraph="Don't have an account yet? "
                linkName="Signup"
                linkUrl="/signup"
                />
            <FavClient/>
        </>
    )
}