import { useState } from 'react';
import { loginFields } from "../constants/formFields";
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";


//axios.defaults.withCredentials = true;


const fields=loginFields;
let fieldsState = {};
fields.forEach(field=>fieldsState[field.id]='');

export default function Login(){
    const [loginState, setLoginState]=useState(fieldsState);
    const [searchParams, setSearchParams] = useSearchParams();    

    
    const handleChange=(e)=>{
        setLoginState({...loginState,[e.target.id]:e.target.value})
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        authenticateUser();
    }

    //Handle Login API Integration here
    //Handle Login API Integration here
    const authenticateUser = () =>{                  
        axios.post('https://authworkinglive.com/auth/login',
                loginState, 
                {
                    headers:{
                        'Access-Control-Allow-Origin': "https://authworkinglive.com",  
                        'Content-Type': 'application/json'                          
                    }
                },  { withCredentials: true })
        .then( (response) => {                  
            const params = searchParams.get('next') 
            const url = process.env.REACT_APP_AUTH_SERVER + decodeURIComponent(params)    
            window.location.replace(url);
        })
        .catch(error => {
            console.log("ERROR: ", error)
        });
    }

    return(
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="-space-y-px">
            {
                fields.map(field=>
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}                            
                    />
                
                )
            }
        </div>

        <FormExtra/>
        <FormAction handleSubmit={handleSubmit} text="Login"/>

      </form>
    )
}