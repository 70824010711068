import Header from "../components/Header"
import Plunder from "../components/Plunder"
import { useState, useEffect } from 'react';

export default function PlunderPage({ text }){
    const [userLogged, setUserLogged]=useState(false);

    useEffect(() => { 
        setUserLogged(JSON.parse(window.sessionStorage.getItem("userLogged")));
    }, []);

    return(
        <div className="grid font-ibm place-items-center">
            <div className="text-center px-8  ">
                <h1 className="px-8 tracking-widest mb-4 font-ibm text-gray-700 dark:text-gray-400">PLUNDER STYLE</h1>
            </div>
            <div className="flex px-8">
                <a  className="block p-6 max-w-sm w-full bg-white rounded-lg border border-zinc-200 shadow-md  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ">                    
                    <Plunder/>
                </a>
            </div>

           
            
        </div>
    )
}