
import { useState } from 'react';
import { signupFields } from "../constants/formFields"
import FormAction from "./FormAction";
import Input from "./Input";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

axios.defaults.withCredentials = true;

const fields=signupFields;
let fieldsState={};

fields.forEach(field => fieldsState[field.id]='');

export default function Signup(){
  const [signupState,setSignupState]=useState(fieldsState);
  const [errorMessage, setErrorMessage] = useState('');  

  const navigate = useNavigate();
  const handleChange=(e)=>setSignupState({...signupState,[e.target.id]:e.target.value});
  const handleSubmit=(e)=>{
    e.preventDefault();    
    createAccount(signupState)
  }

  //handle Signup API Integration here
  const createAccount=(signupState)=>{
    //axios.defaults.withCredentials = true;
    axios.defaults.withCredentials = true;
    axios.post(process.env.REACT_APP_AUTH_SERVER+'/users/v2/add-user', signupState, { headers:{'Access-Control-Allow-Origin': process.env.REACT_APP_AUTH_SERVER}},  { withCredentials: true })
    .then( (response) => {
        console.log("response")
        navigate('/login')
    })          
    .catch(error => {
        console.log("ERROR: ", error)
        console.log(error.response.data)
        const err = error.response.data.split(":")

        setErrorMessage(err);
        navigate('/signup')
    });
  }

    return(
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="">
        {
                fields.map(field=>
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={signupState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                    />                
                )
          }
            {
              errorMessage && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold"> { errorMessage[0] } </strong>
                <span className="block sm:inline">{ errorMessage[1] }</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  
                </span>
              </div>
            )}
          <FormAction handleSubmit={handleSubmit} text="Signup" />
        </div>

         

      </form>
    )
}
